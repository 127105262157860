import axios from 'axios'

const state = () => ({
  game_id: null,
  round: 0,
  players: [],
  processing: false,
})

const getters = {
  isNewGame: state => state.game_id == null,
}

const mutations = {
  newGame(state, game_id) {
    state.game_id = game_id;
    state.players = [];
  },
  addPlayer(state, player) {
    state.players.push(player);
  },
  newRound(state, players) { 
    state.players = players;
  },
  finishGame(state) {
    state.game_id = null;
    state.players = [];
  },
  changeProcessing(state, val) {
    state.processing = val;
  }
}

const actions = {
  addPlayer({ commit, state }, name) {
    commit("changeProcessing", true);
    axios.post(
      '/api/v1/games/domino/player/',
      {game_id: state.game_id, name: name}
    ).then(res => commit("addPlayer", res.data)
    ).catch(err => commit('setError', err.message, {root: true}));
    commit("changeProcessing", false);
  },
  createGame({ commit }) {
    commit("changeProcessing", true);
    axios.post(
      '/api/v1/games/domino/create/', {}
    ).then(res => commit("newGame", res.data.game_id)
    ).catch(err => commit('setError', err.message, {root: true}));
    commit("changeProcessing", false);
  },
  newRound({ commit, state }, players) {
    commit("changeProcessing", true);
    axios.post(
      '/api/v1/games/domino/round/',
      {game_id: state.game_id, scores: players},
    ).then(res => commit("newRound", res.data)
    ).catch(err => commit('setError', err.message, {root: true}));
    commit("changeProcessing", false);
  },
}

const modules = {

}

export default ({
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules,
})
