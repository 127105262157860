<template>
  <v-app>
    <v-main>
      <MainMenu />
      <v-alert type="error" dense dismissible transition="scale-transition" v-model="showError">
        {{ errorMessage }}
      </v-alert>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import MainMenu from "@/components/MainMenu.vue";

export default {
  components: {
    MainMenu,
  },
  data: () => ({
    menuDrawer: false,
  }),
  computed: {
    showError: {
      get() { return this.$store.state.showError },
      set() { this.$store.commit('cleanError')}
    },
    ...mapState({
      errorMessage: state => state.errorMessage,
    })
  },
  mounted(){
    this.$store.dispatch('getSession');
  }
};
</script>


<style lang="scss">
a {
  text-decoration: none;
}
</style>
