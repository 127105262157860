<template>
    <v-data-table :headers="headers" :items="players" item-key="id" hide-default-footer mobile-breakpoint="0">
        <template v-slot:[`item.scores`]="{ item }">
            <v-chip :color="isLose(item.scores)">
                {{ summaryScores(item.scores) }}
            </v-chip>
        </template>
        <template v-slot:[`item.current`]="{ item } ">
            <v-text-field type="number" label="Набрано очков" v-model.number="item.score">Score</v-text-field>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "PlayerList",
    props: ['players'],

    data: () => ({
        headers: [
            {'text': "Имя", value: "name", sortable: false},
            {'text': "Всего", value: "scores", sortable: false},
            {'text': "Текущий", value: "current", sortable: false},
        ],
    }),

    computed: {
    },

    methods: {
        summaryScores: (s) => Object.values(s).reduce((a, b) => parseInt(a) + parseInt(b)),
        isLose(scores) {
            return this.summaryScores(scores) >= 100 ? 'red lighten-3' : 'green lighten-5'
        },
    },
}
</script>