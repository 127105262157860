<template>
    <div class="mx-auto overflow-hidden">
        <v-app-bar>
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-toolbar-title>Q-Realm</v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" absolute temporary>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon x-large>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{userName}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-btn icon plain fab x-large v-if="userName" @click="logout"><v-icon>mdi-logout</v-icon></v-btn>
                    <router-link to="/user/" v-else>
                        <v-btn icon plain fab x-large><v-icon>mdi-login</v-icon></v-btn>
                    </router-link>
                </v-list-item-icon>
            </v-list-item>
            <v-divider></v-divider>

            <v-treeview :items="items" dense activatable hoverable open-on-click>
                <template v-slot:prepend="{ item }">
                    <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                </template>
                <template v-slot:label="{ item }">
                    <router-link :to="item.route" v-if="item.route">
                        {{ item.name }}</router-link>
                    <div v-else>{{ item.name }}</div>
                </template>
            </v-treeview>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapState } from 'vuex';

    export default {
        name: 'MainMenu',

        data: () => ({
            drawer: false,
            menu: [
                {id: "main", name: "Главная", route: "/", icon: "mdi-home", access: "_all_"},
                {
                    id: "fun", name: "Развелечения", icon: "mdi-account-group", access: "_all_",
                    children: [
                        {id: "talk", name: "Общение", route: "/games/talk/", icon: "mdi-account-voice", access: "games:talk:view"},
                        {id: "domino", name: "Доминошка", route: "/games/domino/", icon: "mdi-gamepad-square", access: "_all_"},
                    ],
                },
            ],
        }),

        computed: {
            ...mapState({
                userName: state => state.user.name,
            }),
            items() {
                let res = [];
                let acl = this.$store.state.user.acl;
                this.menu.forEach((item) => {
                    let children = []
                    if (item.children) {
                        children = item.children.filter((o) => o.access == "_all_" ? true : acl[o.access])
                    }
                    if (item.access == '_all_' || acl[item.access]) {
                        res.push({id: item.id, name: item.name, route: item.route, icon: item.icon, children: children})
                    }
                })
                return res
            },
        },
        
        methods: {
            logout() {
                this.$store.dispatch("logoutUser");
                this.drawer = false;
            },
        },
    }
</script>

<style scoped>
.v-application a {
    color: black;
}
</style>