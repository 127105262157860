<template>
<div>
  <h2 class="text-h3 mb-5">Добро пожаловать!</h2>
  <p>
    Данный ресурс создан для себя и друзей себя :)
  </p>
</div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>
