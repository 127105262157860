<template>
<div>
    <h2 class="text-h3 mb-5">{{ title }}</h2>
    <MainFrame />
</div>
</template>

<script>
import { mapState } from "vuex";
import MainFrame from '@/components/games/domino/MainFrame.vue'

export default ({
    name: "DominoGame",
    components: { MainFrame },

    data: () => ({
        title: "Доминошка"
    }),

    computed: {
        ...mapState({
            accessView: state => 'games:domino:view' in state.user.acl ? state.user.acl['games:domino:view'] : false,
        })
    }
          

})
</script>
