import axios from 'axios'

const state = () => ({
  currentCard: {
    id: null,
    number: '0',
    type: 'FRIEND',
    text: '',
    enabled: false,
  },
  cards: [],
  cardTypesWithClass: {
    FRIEND: {css: 'blue lighten-5', name: 'Познакомиться'},
    LOVE: {css: 'pink lighten-5', name: 'Стать ближе'},
    TOGETHER: {css: 'pink lighten-4', name: 'Узнать друг друга'},
    SELF: {css: 'red lighten-5', name: 'Самооценка'},
    PROFFESION: {css: 'orange lighten-5', name: 'Проф. деятельность'},
    EMOTION: {css: 'green lighten-5', name: 'Настроение'},
    RELATIONSHIP: {css: 'purple lighten-5', name: 'Отношения'},
    DIALOGS: {css: 'brown lighten-5', name: 'Темы для диалога'},
  },
  currentView: "RandomCard",
})

const getters = {
  cardTypeClass: state => state.cardTypesWithClass[state.currentCard.type].css,
  cardTypeSelect: state => Object.entries(state.cardTypesWithClass).map((obj) => ({item: obj[0], name: obj[1].name})),
}

const mutations = {
  changeView(state, view) { state.currentView = view },
  newCard(state) {
    state.currentCard = {
      id: null,
      number: "",
      type: 'FRIEND',
      text: '',
      enabled: false,
    }
  },
  updateCards(state, cards) { state.cards = cards},
  updateCurrentCard(state, card) { state.currentCard = card },
}

const actions = {
  getAllCards({ commit }) {
    axios.post(
      '/api/v1/games/talk/list/'
    ).then((res) => {
      if(res.data) commit('updateCards', res.data)
    }).catch((err) => {
      commit('setError', err.message, {root: true})
    })
  },
  getRandomCard({ commit }, cardType) {
    axios.post(
      "/api/v1/games/talk/", {cardType: cardType}
    ).then((res) => {
      if (res.data) commit('updateCurrentCard', res.data);
    }).catch((err) => {
      commit('setError', err.message, {root: true})
    });
  },
  saveCard({ commit }, card) {
    axios.post(
      '/api/v1/games/talk/card/', card
    ).then(() => {
      commit('updateCurrentCard', card);
      commit('changeView', 'CardsList');
    }).catch((err) => {
      commit('setError', err.message, {root: true})
    })
  },
}

const modules = {

}

export default ({
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules,
})
