<template>
	<v-row>
		<v-col cols="12">
			<v-footer padless fixed>
				<v-bottom-navigation grow>
					<v-btn @click="newCard"><span>New</span><v-icon color="green">mdi-plus-circle-outline</v-icon></v-btn>
					<v-btn @click="cardsList"><span>List</span><v-icon color="primary">mdi-view-list</v-icon></v-btn>
					<v-btn @click="editCard" v-if="currentView == 'RandomCard'">
						<span>Edit</span><v-icon color="orange">mdi-pencil</v-icon>
					</v-btn>
					<v-btn @click="getCard" v-if="currentView == 'EditCard' || currentView == 'CardsList'">
						<span>Card</span><v-icon color="primary">mdi-card-text-outline</v-icon>
					</v-btn>
				</v-bottom-navigation>
			</v-footer>
		</v-col>
	</v-row>
</template>


<script>
import { mapState } from 'vuex'

export default ({
	name: "AdminPanel",
	data: () => ({

	}),
	computed: {
		...mapState({
			currentView: state => state.games.talk.currentView,
		}),
	},
	methods: {
		cardsList() { this.$store.commit('games/talk/changeView', 'CardsList')},
		editCard() { this.$store.commit('games/talk/changeView', 'EditCard')},
		getCard() { this.$store.commit('games/talk/changeView', 'RandomCard')},
		newCard() {
			this.$store.commit('games/talk/newCard');
			this.$store.commit('games/talk/changeView', 'EditCard');
		},
	}
})
</script>