<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mb-12">
        <v-col cols="12">
          <h2 class="text-h3 mb-5">{{ title }}</h2>
          <component :is="currentView"></component>
        </v-col>
      </v-row>
      <AdminPanel v-if="accessEdit"/>
    </v-col>
  </v-row>

  
</template>

<script>
import { mapState } from 'vuex'
import AdminPanel from '@/components/games/talk/AdminPanel.vue'
import CardsList from '@/components/games/talk/CardsList.vue'
import EditCard from '@/components/games/talk/EditCard.vue'
import RandomCard from "@/components/games/talk/RandomCard.vue"

export default ({
  name: "TalkGame",
  components: { AdminPanel, CardsList, EditCard, RandomCard},
  data: () => ({
    title: "Random coffee",
  }),
  computed: {
    ...mapState({
      currentView: state => state.games.talk.currentView,
      accessEdit: state => 'games:talk:edit' in state.user.acl ? state.user.acl['games:talk:edit'] : false,
    })
  },
  methods: {
    
  },
});
</script>
