<template>
	<v-list-group :value="false">
		<template v-slot:activator><v-list-item-title>{{ title }}</v-list-item-title></template>
		<v-list>
			<v-list-item :class="cardClass(card)" v-for="card in cards" :key="card.id">
				<v-list-item-content>
					{{ card.number }}: {{ card.text }}
				</v-list-item-content>
				<v-list-item-action>
					<v-btn small fab outlined @click="editCard(card)">
						<v-icon color="orange">mdi-pencil</v-icon>
					</v-btn>
				</v-list-item-action>
			</v-list-item>
		</v-list>
	</v-list-group>
</template>

<script>

export default ({
	props: ['cards', 'title'],
	methods: {
		cardClass(card) {
			if (card.enabled) {
				return this.$store.state.games.talk.cardTypesWithClass[card.type].css;
			} else {
				return 'blue-grey lighten-5'
			} 
		},
		editCard(card) {
			this.$store.commit('games/talk/updateCurrentCard', card);
			this.$store.commit('games/talk/changeView', 'EditCard');
		},
	},
})
</script>