import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DominoGame from '../views/games/DominoGameView.vue'
import TalkGame from '../views/games/TalkGameView.vue'
import UserView from '../views/users/UserView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/games/talk/',
        name: 'talkGame',
        component: TalkGame,
    },
    {
        path: '/games/domino/',
        name: 'dominoGame',
        component: DominoGame,
    },
    {
        path: '/user/',
        name: 'userInfo',
        component: UserView,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
