<template>
<div>
    <h2 class="text-h3 mb-5">{{ userName }}</h2>
    <p>Добро пожаловать!</p>
    <p>Можешь выбрать слева в меню, интересующий тебя раздел.</p>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "UserInfo",

    computed: {
        ...mapState({
            userName: state => state.user.name,
        })
    }
}
</script>