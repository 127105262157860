<template>
	<div v-if="accessEdit">
		<CardSublist title="Познакомиться" :cards="filteredCards('FRIEND')"/>
		<CardSublist title="Стать ближе" :cards="filteredCards('LOVE')"/>
		<CardSublist title="Узнать друг друга" :cards="filteredCards('TOGETHER')"/>
		<v-divider></v-divider>
		<CardSublist title="Самооценка" :cards="filteredCards('SELF')"/>
		<CardSublist title="Проф. деятельность" :cards="filteredCards('PROFFESION')"/>
		<CardSublist title="Настроение" :cards="filteredCards('EMOTION')"/>
		<CardSublist title="Отношения" :cards="filteredCards('RELATIONSHIP')"/>
		<v-divider></v-divider>
		<CardSublist title="Темы для диалога" :cards="filteredCards('DIALOGS')"/>
	</div>
</template>

<script>
import {mapState } from 'vuex'
import CardSublist from '@/components/games/talk/CardSublist.vue'

export default ({
    name: "CardsList",
		components: {CardSublist},
    data: () => ({

    }),

    computed: {
			...mapState({
				cards: state => state.games.talk.cards,
				accessEdit: state => 'games:talk:edit' in state.user.acl ? state.user.acl['games:talk:edit'] : false,
			}),
    },

	methods: {
		filteredCards(cardType) {
			return this.$store.state.games.talk.cards.filter(card => card.type == cardType)
		},
	},
	mounted() {
		this.$store.dispatch('games/talk/getAllCards');
	},
})
</script>

<style>
</style>