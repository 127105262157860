<template>
<div>
    <v-row>
        <v-col cols="12">
            <PlayerList :players="players" v-if="players.length"/>
        </v-col>
    </v-row>
    <v-row v-if="isNewGame">
        <v-col cols="12">
            <v-btn block color="success"
                :loading="processing" @click="createGame">Новая игра</v-btn>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12">
            <v-row>
                <v-col cols="12">
                    <v-text-field type="text" label="Добавить игрока" v-model="newPlayer"
                        :rules="[required]"
                        append-outer-icon="mdi-account-plus"
                        @click:append-outer="addPlayer"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-btn block color="primary"
                        :loading="processing" @click="newRound">Новый раунд</v-btn>
                </v-col>
                <v-col cols="12">
                    <FinishDialog />
                </v-col>
            </v-row>
        </v-col>
        
    </v-row>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FinishDialog from '@/components/games/domino/FinishDialog.vue'
import PlayerList from '@/components/games/domino/PlayerList.vue'

export default ({
    name: "MainFrame",
    components: { PlayerList, FinishDialog },

    data: () => ({
        newPlayer: null,
        required: value => !!value || "required!",
    }),

    computed: {
        ...mapState('games/domino', {
            players: state => JSON.parse(JSON.stringify(state.players)),
            processing: state => state.processing,
        }),
        ...mapGetters('games/domino', {
            isNewGame: 'isNewGame',
        }),
    },

    methods: {
        addPlayer() {
            if (this.newPlayer) {
                this.$store.dispatch('games/domino/addPlayer', this.newPlayer);
                this.newPlayer = null;
            }
        },
        createGame() { this.$store.dispatch('games/domino/createGame') },
        newRound() { this.$store.dispatch('games/domino/newRound', this.players) },
    },
})
</script>

<style>
div.v-input__append-outer {
    margin-left: 20px !important;
    margin-right: 10px;
}
</style>