import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import TalkGame from './games/talk.js'
import DominoGame from './games/domino.js'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    user: {
      name: "",
      acl: {},
    },
    errorMessage: "",
    showError: false,
  },

  getters: {
    
  },

  mutations: {
    // Error storage
    cleanError(state) { state.showError = false },
    setError(state, message) {
      state.errorMessage = message;
      state.showError = true;
    },
    // User storage
    updateUser(state, user) {
      state.user.name = user.username;
      state.user.acl = user.acl;
    },
  },

  actions: {
    getSession({ commit }) {
      axios.get(
        "/api/v1/users/session/"
      ).then((res) => {
        let user = {username: res.data.username, acl: {}}
        user.acl = res.data
        if (res.data) commit('updateUser', user);
      }).catch((err) => {
        commit('setError', err.message)
      })
    },
    loginUser({ commit }, user) {
      axios.post(
        "/api/v1/users/login/", {username: user.username, password: user.password}
      ).then((res) => {
        let user = {username: res.data.username}
        user.acl = res.data
        if (res.data) commit('updateUser', user);
      }).catch((err) => {
        commit('setError', err.message)
      })
    },
    logoutUser({ commit }) {
      axios.post(
        "/api/v1/users/logout/"
      ).then(() => {
        commit("updateUser", {username: "", acl: {}})
      }).catch((err) => {
        commit('setError', err.message)
      })
    }
  },

  modules: {
    games: {
      namespaced: true,
      modules: {
        talk: TalkGame,
        domino: DominoGame,
      }
    },
  }
})
