<template>
  <div v-if="accessView">
    <v-row>
      <v-col cols="12">
        <v-select multiple chips outlined v-model="cardType" label="Набор карт"
                  :items="cardTypeSelect" item-value="item" item-text="name">
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card :class="cardTypeClass">
          <div v-if="currentCard.id">
            <v-card-text class="text-h5 text-center">{{ currentCard.text }}</v-card-text>
          </div>
          <v-card-actions>
            <v-btn block large outlined @click="getRandomCard">Следующая карта</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import  { mapGetters, mapState} from 'vuex'

export default ({
  name: "RandomCard",
  data: () => ({
    cardType: ["FRIEND"],
  }),
  methods: {
    getRandomCard() { this.$store.dispatch('games/talk/getRandomCard', this.cardType) },
  },
  computed: {
    ...mapState({
      currentCard: state => state.games.talk.currentCard,
      accessView: state => 'games:talk:view' in state.user.acl ? state.user.acl['games:talk:view'] : false,
    }),
    ...mapGetters('games/talk', {
      cardTypeClass: 'cardTypeClass',
      cardTypeSelect: 'cardTypeSelect',
    }),
  },
  mounted() {
    this.getRandomCard();
  },
})
</script>