import { render, staticRenderFns } from "./MainMenu.vue?vue&type=template&id=57193096&scoped=true&"
import script from "./MainMenu.vue?vue&type=script&lang=js&"
export * from "./MainMenu.vue?vue&type=script&lang=js&"
import style0 from "./MainMenu.vue?vue&type=style&index=0&id=57193096&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../.nvm/versions/node/v16.15.0/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57193096",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VAppBar,VAppBarNavIcon,VBtn,VDivider,VIcon,VListItem,VListItemAvatar,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer,VToolbarTitle,VTreeview})
