<template>
    <div>
        <v-row>
            <v-col cols="12">
                <h2 class="text-h3 mb-5">{{ title }}</h2>
            </v-col>
        </v-row>
        <v-row>
			<v-col cols="12">
				<v-text-field label="User login" outlined v-model="username"></v-text-field>
            </v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-text-field label="Password" type="password" outlined v-model="password"></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-btn block outlined @click="confirmLogin">{{title}}</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
    name: "UserLogin",
    data: () => ({
        title: "LogIn",
        username: "",
        password: "",
    }),
    methods: {
        confirmLogin() {
            this.$store.dispatch('loginUser', {username: this.username, password: this.password})
        }
    }
}
</script>