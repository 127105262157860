<template>
    <div>
        <component :is="currentComponent"></component>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import UserInfo from '@/components/users/UserInfo.vue'
import UserLogin from '@/components/users/UserLogin.vue'


export default {
    name: "UserView",
    components: {UserLogin, UserInfo},

    computed: {
        ...mapState({
            userName: state => state.user.name,
        }),
        currentComponent() {
            if (this.$store.state.user.name) {
                return UserInfo
            } else {
                return UserLogin
            }
        }
    }
}
</script>