<template>
<div v-if="accessEdit">
	<v-row>
		<v-col cols="6">
			<v-text-field label="Номер RC" outlined v-model="card.number"></v-text-field>
	</v-col>
		<v-col class="align-right" cols="6">
			<v-switch inset label="В колоде" v-model="card.enabled"></v-switch>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="12">
			<v-select outlined v-model="card.type" label="Набор карт"
				:items="cardTypeSelect" item-value="item" item-text="name">
	</v-select>
	</v-col>
	</v-row>
	<v-row>
		<v-col cols="12">
			<v-textarea label="Текст карточки" outlined no-resize rows="4" v-model="card.text"></v-textarea>
		</v-col>
	</v-row>
	<v-row>
		<v-col cols="6">
			<v-btn block outlined color="error" @click="cancelEdit">Отмена</v-btn>
		</v-col>
		<v-col cols="6">
			<v-btn block outlined color="success" :loading="saving" @click="saveCard">Сохранить</v-btn>
		</v-col>
	</v-row>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default ({
	name: "EditCard",
	data: () => ({
		card: { /* copy from games.talk store */ },
		saving: false,
	}),
	computed: {
		...mapState({
			accessEdit: state => 'games:talk:edit' in state.user.acl ? state.user.acl['games:talk:edit'] : false,
		}),
		...mapGetters('games/talk', {
			cardTypeSelect: 'cardTypeSelect',
		}),
	},
	methods: {
		cancelEdit() { this.$store.commit('games/talk/changeView', 'RandomCard')},
		async saveCard() {
			this.saving = true;
			this.$store.dispatch('games/talk/saveCard', this.card);
		}
	},
	mounted() {
		this.card = {...this.$store.state.games.talk.currentCard};
	}
})
</script>

<style>
</style>