<template>
    <div class="text-center">
        <v-dialog v-model="askFinished" persistent max-width="400">
            <template v-slot:activator="{ on, attrs }">
                <v-btn block color="error" v-bind="attrs" v-on="on">Завершить игру</v-btn>
            </template>
            <v-card>
                <v-card-title>Хотите завершить игру?</v-card-title>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green" text @click="finishGame">Да</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="askFinished = false">Нет</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    
</template>

<script>
export default {
    name: "FinishDialog",

    data: () => ({
        askFinished: false
    }),

    methods: {
        finishGame() {
            this.askFinished = false;
            this.$store.commit("games/domino/finishGame");
        }
    },
}
</script>